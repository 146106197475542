<template>
  <div class="page stream-page">
    <div class="stream-page__container">
      <div class="stream-page__video">
        <button
          @click="joinBroadcast"
          v-if="!isStreamStart && !streamLoading"
          class="btn btn--lg btn--green stream-page__btn"
        >
          Смотреть
        </button>
        <button v-if="streamLoading && !isStreamStart" class="btn btn--lg btn--green stream-page__btn">
          <LoadingIndicator title="Загрузка" />
        </button>

        <video id="video" width="640" height="800" ref="viewer" autoplay></video>
      </div>
      <div v-if="isStreamStart" class="stream-page__chat-wrapper">
        <div class="stream-page__chat-header">
          <h3>Чат</h3>
          <div class="stream-page__chat-live">
            <div class="stream-page__chat-head">LIVE</div>
            <div class="stream-page__chat-count">{{ streamingUsers.length }}</div>
          </div>
        </div>
        <ul class="stream-page__chat">
          <li v-for="(item, i) in chat" :key="i">
            <span style="font-weight: 500; color: orange">{{ item.name || "Guest" }}:</span>
            {{ item.comment }}
          </li>
        </ul>
        <div class="stream-page__input">
          <InputComponent v-model="message" placeholder="Ваш комментарий" @enter="sendMessage" />
          <div @click="sendMessage">
            <RemixIconComponent class="stream-page__input-svg" category="Business" name="send-plane-line" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from "simple-peer";
import axios from "axios";
import CREATE_COMMENT from "@/graphql/mutations/CreateComment.graphql";
import STREAM from "@/graphql/queries/stream.graphql";
import Echo from "laravel-echo";
import { getCookie } from "../../utils";
import LoadingIndicator from "components/LoadingIndicator.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";

export default {
  name: "StreamPage",
  components: { RemixIconComponent, InputComponent, LoadingIndicator },

  data() {
    return {
      isStreamStart: false,
      streamingPresenceChannel: null,
      broadcasterPeer: null,
      broadcasterId: null,
      streamLoading: false,
      message: null,
      chat: [],
      streamingUsers: [],
    };
  },

  mounted() {
    this.play();
  },
  beforeDestroy() {
    location.reload();
    this.removeBroadcastVideo();
    if (this.broadcasterPeer) {
      this.broadcasterPeer.destroy();
    }
  },

  methods: {
    play() {
      // При переходе с другой страницы (параметр autoplay) будет автоматически грузиться стрим
      // при перезагрузке же страницы будет показываться кнопка, после нажатия которой будет грузиться стрим
      // это нужно т.к. браузер запрещает воспроизводить пользователю звуки до его действий на сайте
      // нужно в сделать загрузку видео отдельно, потом при нажатии кнопки включается звук, как на twitch
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("autoplay")) {
        this.joinBroadcast();
        urlParams.delete("autoplay");
        history.replaceState(null, "", location.pathname + "?" + urlParams.toString());
      }
    },
    async joinBroadcast() {
      this.streamLoading = true;
      await this.initializeStreamingChannel();
      await this.initializeSignalOfferChannel();
      await this.initializeSignalIceCadndidateOfferChannel();
    },

    async initializeStreamingChannel() {
      this.streamingPresenceChannel = window.Echo.join(`streaming-channel.${this.link}`).listen(
        "StreamCommentCreated",
        (data) => {
          //chat
          const comment = data.comment.comment;
          const name = data.comment.user.name ? data.comment.user.name : `Guest${data.comment.user.id}`;
          const result = {
            name,
            comment,
          };

          this.chat = [...this.chat, result];
          console.log("asdasd", data.comment.comment);
        }
      );

      this.streamingPresenceChannel.here((users) => {
        this.streamingUsers = users;
        console.log("users", users);
      });

      this.streamingPresenceChannel.joining((user) => {
        console.log("New User", user);
        // if this new user is not already on the call, send your stream offer
        const joiningUserIndex = this.streamingUsers.findIndex((data) => data.id === user.id);
        if (joiningUserIndex < 0) {
          this.streamingUsers.push(user);
        }
      });

      this.streamingPresenceChannel.leaving((user) => {
        console.log(user, "Left");
        const leavingUserIndex = this.streamingUsers.findIndex((data) => data.id === user.id);
        this.streamingUsers.splice(leavingUserIndex, 1);
      });
    },

    async sendMessage() {
      await this.$apollo.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          stream_id: parseInt(this.streamId),
          comment: this.message,
        },
        context: {
          headers: {
            Authorization: "Bearer " + this.$store.state.apollo_token,
          },
        },
      });
      this.message = null;
    },

    createViewerPeer(incomingOffer, broadcaster) {
      const peer = new Peer({
        initiator: false,
        trickle: true,
        config: {
          iceServers: [
            {
              urls: "stun:stun.stunprotocol.org",
            },
            {
              urls: this.$store.state._env.TURN_SERVER_URL,
              username: this.$store.state._env.TURN_SERVER_USERNAME,
              credential: this.$store.state._env.TURN_SERVER_CREDENTIAL,
            },
          ],
        },
      });
      // Add Transceivers
      peer.addTransceiver("video", { direction: "recvonly" });
      peer.addTransceiver("audio", { direction: "recvonly" });

      // Initialize Peer events for connection to remote peer
      this.handlePeerEvents(peer, incomingOffer, broadcaster, this.removeBroadcastVideo);

      this.broadcasterPeer = peer;
    },

    handlePeerEvents(peer, incomingOffer, broadcaster, cleanupCallback) {
      console.log("incomingOffer", incomingOffer);
      console.log("peer", peer);
      peer.on("signal", (data) => {
        if (data.type === "candidate") {
          console.log("вот тут брат кандидат", data.candidate);
          /** ОТПРАВЛЯЕМ КАНДИДАТОВ НА СЕРВЕР **/
          axios
            .post(this.$store.state._env.MEDIA_ENDPOINT + "/action/stream-ice-candidate-answer", {
              broadcaster: this.broadcasterId,
              candidate: data.candidate,
            })
            .then((res) => {
              // console.log("result", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }

        if (data.type === "answer") {
          console.log("вот тут оффер брат ", data);
          axios
            .post(this.$store.state._env.MEDIA_ENDPOINT + "/action/stream-answer", {
              broadcaster,
              answer: data.sdp,
            })
            .then((res) => {
              console.log("result", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });

      peer.on("stream", (stream) => {
        console.log("stream", stream);
        // display remote stream
        let video = this.$refs.viewer;

        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }

        this.isStreamStart = true;
        video.play();

        console.log("video", video);
      });

      peer.on("track", (track, stream) => {
        console.log("onTrack");
        console.log("track", track);
        console.log("stream", stream);
      });

      peer.on("connect", () => {
        console.log("Viewer Peer connected");
      });

      peer.on("close", () => {
        console.log("Viewer Peer closed");
        // peer.destroy();
        // cleanupCallback();
      });

      peer.on("error", (err) => {
        console.log("handle error gracefully", err);
      });

      console.log("incomingOffer", incomingOffer);
      if (incomingOffer.type === "offer") {
        const updatedOffer = {
          ...incomingOffer,
          sdp: `${incomingOffer.sdp}\n`,
        };

        peer.signal(updatedOffer);
      }
    },

    async initializeSignalOfferChannel() {
      // console.log(126);
      await window.Echo.private(`stream-signal-channel.${this.authUserId}`).listen(
        "StreamOffer",
        ({ data }) => {
          if (data.offer.type === "offer") {
            console.log("Signal Offer from private channel", data);
            this.broadcasterId = data.broadcaster;
            this.createViewerPeer(data.offer, data.broadcaster);
          }
        }
      );
    },
    async initializeSignalIceCadndidateOfferChannel() {
      await window.Echo.private(`stream-signal-channel.${this.authUserId}`).listen(
        "StreamCandidateOffer",
        ({ data }) => {
          console.log("Кандидат с сервера", data);
          console.log("бродкастер пир", this.broadcasterPeer);

          this.broadcasterPeer.signal(data.candidate);

          // const iceCandidate = new RTCIceCandidate(data.candidat);
          // const iceCandidate = new RTCIceCandidate({
          //   type: "candidate",
          //   candidate: data.candidat.candidate,
          //   sdpMid: data.candidat.sdpMid,
          //   sdpMLineIndex: data.candidat.sdpMLineIndex,
          // });
          // console.log(iceCandidate);
          // this.broadcasterPeer.addIceCandidate(iceCandidate);

          // axios
          //     .post(this.$store.state._env.MEDIA_ENDPOINT + "/action/stream-ice-candidat-answer", {
          //       broadcaster: this.broadcasterId,
          //       answer: data.candidat,
          //     })
          //     .then((res) => {
          //       console.log("result", res);
          //     })
          //     .catch((err) => {
          //       console.log("err", err);
          //     });
          // if (data.offer.type === "candidate") {
          //   console.log("Кандидаты", data);
          //   // this.broadcasterId = data.broadcaster;
          //   // this.createViewerPeer(data.offer, data.broadcaster);
          // }
        }
      );
    },

    removeBroadcastVideo() {
      console.log("removingBroadcast Video");
      // alert("Livestream ended by broadcaster");
      const tracks = this.$refs.viewer.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs.viewer.srcObject = null;
    },
  },
  computed: {
    link() {
      return this.$route.params.link;
    },
    streamId() {
      return this.$route.query.streamId;
    },
    authUserId() {
      return this.$store.state.auth.user.id;
    },
  },
};
</script>

<style lang="stylus">
.stream-page {
  display: flex;
  //justify-content center
  //gap 48px
  //padding: 32px 0 48px;

  &__container {
    width 100%
    display grid
    grid-template-columns 0.7fr 0.3fr
    //max-width: var(--main_width);
    //width: 100%;

    +below(600px) {
      display block
      //grid-template-columns 1fr
    }
  }

  &__video {
    position relative
    width 100%

    video {
      width: 100%;
      transform: scaleX(-1);

      +below(600px) {
        height: 500px;
      }
    }
  }

  &__chat {
    height 700px
    overflow-y: auto;

    +below(1500px) {
      height 600px
    }

    +below(1100px) {
      height 550px
    }

    +below(919px) {
      height 500px
    }

    +below(600px) {
      height: 180px;

    }
  }

  &__chat-header {
    display flex
    justify-content space-between
    align-items center
  }

  &__chat-live {
    display flex
    flex-direction column
    gap 5px
    align-items center
    justify-content center
  }

  &__chat-head {
    display inline-flex
    justify-content center
    align-items center
    width 40px
    height 20px
    border-radius 6px
    padding 2px 5px
    font-weight 400
    font-size 0.5rem
    line-height  9px
    color: #EF7318
    box-shadow: 0 5px 10px -4px rgba(34, 60, 80, 0.2);
  }

  &__chat-count {
    display inline-flex
    justify-content center
    align-items center
    width 28px
    height 15px
    border-radius 6px
    color: #EF7318
    font-size 0.5rem
    box-shadow: 0 5px 10px -4px rgba(34, 60, 80, 0.2);
  }

  &__chat-wrapper {
    padding-right 100px

    +below(1500px) {
      padding-right 0px
    }

    +below(600px) {
      h3 {
        display none
      }
      padding-bottom: 20px;
      padding-right 0
      width: 100%;
      //position: absolute;
    }
  }

  &__input {
    position relative
    width 100%

    .input {
      width 100%
    }

    &-svg {
      width 24px
      height 24px
      position absolute
      top: 10px;
      right: 15px;
      z-index: 1;
      cursor pointer

      path {
        fill var(--blue-500)
      }
    }
  }

  &__btn {
    position absolute
    top 35%
    left: 65%;
    z-index 10

    +below(600px) {
      left: 40%;
    }
  }

  //+below(860px) {
  //  gap: 30px;
  //  padding: 15px 0;
  //}

}
</style>
